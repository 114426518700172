import React from "react"
import styled from "styled-components"

import Modal from "../Modal"
import { Text } from "../core/commonExports"

import CircleChecked from "../../assets/images/circle-checked.svg"

const states = {
  approveDetails: "approve-details",
  sendToInsurer: "send-to-insurer",
  fetchQuotes: "fetch-quotes"
}

const stateDetailsMap = {
  "approve-details": {
    title: "Your details are being approved",
    subtitle: "Wait while we approve your details to ensure everything is up to date."
  },
  "send-to-insurer": {
    title: "Sending the details to Insurers",
    subtitle: "We will be getting back the best quotes for you"
  },
  "fetch-quotes": {
    title: "Last Step! Fetching quotes ",
    "subtitle": "Get ready to insure your business with the best quotes!"
  }
}

const SVGProgressCircle = ({progress}) => {
  return (
    <svg height="90" width="90" style={{transform: "rotate(90deg) scaleY(-1) rotate3d(0, 1, 0, 180deg)"}}>
      <SVGCircle cx="45" cy="45" r="37" stroke="#57BA70" strokeWidth="16" fill="#57BA70" progress={progress} />
    </svg>
  )
}

const CompletedStep = ({details}) => {
  return (
    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
      <Text fontSize="16px" mfontSize="14px" lineHeight="18px" mlineHeight="16px" fontWeight="400" style={{margin: "0"}}> 
        {details}
      </Text>
      <CircleChecked />
    </div>
  )
}

const RFQGenerationLoader = ({
  autoRfqTimerExpired = false,
}) => {
  const [loaderState, setLoaderState] = React.useState(() => autoRfqTimerExpired ? states.fetchQuotes : states.approveDetails)
  // const [intervalHandler, setIntervalHandler] = React.useState(null)
	const [timeLeft, setTimeLeft] = React.useState(() => autoRfqTimerExpired ? 5 : 25)

  const sentToInsurers = loaderState === states.fetchQuotes
  const detailsApproved = loaderState === states.sendToInsurer || sentToInsurers

  const timer = new Date(timeLeft * 1000).toISOString().slice(14, 19)

  const totalTime = autoRfqTimerExpired ? 5 : 25

  React.useEffect(() => {
    let intervalId
    if (timeLeft) {
      intervalId = setInterval(() => {
        if (timeLeft === 20) {
          setLoaderState(states.sendToInsurer)
        } else if (timeLeft === 10) {
          setLoaderState(states.fetchQuotes)
        }
        setTimeLeft(timeLeft - 1)
      }, 1000)
    } else {
      clearInterval(intervalId)
    }
    return () => clearInterval(intervalId)
  }, [timeLeft])

  return (
    <Modal showClose={false}>
      <TimerContainer>
        <SVGProgressCircle progress={(timeLeft / totalTime) * 100} />
        <TimerDisplay>
          {timer}
        </TimerDisplay>
      </TimerContainer>
      <CompletedStepsContainer>
        {detailsApproved && <CompletedStep details="Details approved" />}
        {sentToInsurers && <CompletedStep details="Details sent to the Insurers" />}
      </CompletedStepsContainer>
      <Text fontSize="20px" mfontSize="16px" lineHeight="24px" mlineHeight="20px" fontWeight="bold" color="#333" style={{margin: "0", marginBottom: "10px"}}>
        {stateDetailsMap?.[loaderState]?.title || ""}
      </Text>
      <Text fontSize="18px" mfontSize="14px" lineHeight="22px" mlineHeight="18px" fontWeight="500" color="#333" style={{margin: "0", textAlign: "center"}}>
        {stateDetailsMap?.[loaderState]?.subtitle || ""}
      </Text>
    </Modal>
  )
}

const SVGCircle = styled.circle`
  stroke-dasharray: 232;
  stroke-dashoffset: ${p => p.progress * 2.32};
  transition: all 0.3s ease;
`

const TimerContainer = styled.div`
  margin: auto;
  position: relative;
  width: 90px;
  height: 90px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #E8FFF2;
`

const TimerDisplay = styled(TimerContainer)`
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #fff;
  color: #333;
  height: 74px;
  width: 74px;
`

const CompletedStepsContainer = styled.div`
  display: flex; 
  margin: 1.5rem 0; 
  gap: 1.75rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`

export default RFQGenerationLoader